
import Vue from "vue"
import isObject from "lodash/isObject"
import { mapState, mapActions } from "vuex"
import type { Field, Datalist } from "@/types/api/fields"
import type { Status } from "@/types/api/event"
import type { Tag } from "@/types/api/tag"

type ApiErr = string | Record<string, string>

export default Vue.extend({
  props: {
    field: { type: Object as VuePropType<Field>, required: true },
    value: { type: undefined, default: undefined },
    error: { type: [String, Object] as VuePropType<ApiErr>, default: "" },
    visibleFields: { type: Array as VuePropType<string[]>, required: true },
    requiredFields: { type: Array as VuePropType<string[]>, required: true },
  },

  data: () => ({
    options: {} as Record<string, any>,
  }),

  computed: {
    ...mapState("app", ["statuses", "tags"]),

    statusOptions (): Record<string, Status["id"]> {
      return Object.fromEntries(this.statuses
        .map((s: Status) => [this.$fmt.localize(s.title), s.id]))
    },

    tagsOptions (): Record<string, Tag["id"]> {
      const { group } = this.field.dataType.options
      let tags: Tag[] = this.tags
      if (group) tags = tags.filter(t => t.group.id === group)
      return Object.fromEntries(tags
        .map(t => [this.$fmt.localize(t.title), t.id]))
    },

    model: {
      get (): any { return this.value },
      set (model: any) {
        let parsedModel = model === "" ? undefined : model
        if (this.field.dataType.type === "number") {
          const numberModel = Number(model)
          parsedModel = Number.isNaN(numberModel) ? undefined : numberModel
        }
        this.$emit("input", parsedModel)
      },
    },

    errorModel: {
      get (): ApiErr { return this.error },
      set (errorModel: ApiErr) { this.$emit("update:error", errorModel) },
    },

    label (): string { return this.$fmt.localize(this.field.title) },

    widgetType (): Field["widget"]["type"] { return this.field.widget.type },

    rules (): Nullable<VeeValidateRules> {
      const { widgetType } = this
      if (widgetType === "number") return "double"
      if (widgetType === "url") return "url"
      return null
    },

    isInput (): boolean {
      return [
        "string",
        "number",
        "address",
      ].includes(this.field.dataType.type) &&
      [
        "text",
        "tel",
        "email",
        "number",
        "url",
        "address",
      ].includes(this.widgetType)
    },

    isTranslatableText (): boolean {
      const { field } = this
      return field.dataType.type === "translatable" &&
        field.widget.type === "translatableText" &&
        Object.values(field.widget.options.children)
          .every(c => c.type === "text")
    },

    isVisible (): boolean {
      return this.visibleFields.includes(this.field.alias)
    },
    isRequired (): boolean {
      return this.requiredFields.includes(this.field.alias)
    },

    placeholder (): Nullable<string> {
      const { widget } = this.field
      if (
        widget.type === "password" ||
        widget.type === "text" ||
        widget.type === "number" ||
        widget.type === "url" ||
        widget.type === "tel" ||
        widget.type === "email" ||
        widget.type === "textarea" ||
        widget.type === "address"
      ) return widget.options.placeholder
      return null
    },

    maxLength (): undefined | number {
      const { widget } = this.field
      if (
        widget.type === "password" ||
        widget.type === "text" ||
        widget.type === "url" ||
        widget.type === "tel" ||
        widget.type === "email" ||
        widget.type === "textarea"
      ) return widget.options.maxLength ?? undefined
      return undefined
    },

    minLength (): undefined | number {
      const { widget } = this.field
      if (
        widget.type === "password" ||
        widget.type === "text" ||
        widget.type === "url" ||
        widget.type === "tel" ||
        widget.type === "textarea"
      ) return widget.options.minLength ?? undefined
      return undefined
    },

    min (): Nullable<number> {
      const { widget } = this.field
      if (
        widget.type === "number" ||
        widget.type === "checkboxes"
      ) return widget.options.min
      return null
    },
    max (): Nullable<number> {
      const { widget } = this.field
      if (
        widget.type === "number" ||
        widget.type === "checkboxes"
      ) return widget.options.max
      return null
    },

    datalist (): Nullable<Datalist> {
      const { widget } = this.field
      if (
        widget.type === "text" ||
        widget.type === "url" ||
        widget.type === "tel" ||
        widget.type === "address"
      ) return widget.options.datalist
      return null
    },

    accept (): Nullable<string> {
      const { widget } = this.field
      if (
        widget.type === "file" ||
        widget.type === "photo"
      ) return widget.options.accept
      return null
    },
    maxSize (): Nullable<number> {
      const { widget } = this.field
      if (
        widget.type === "file" ||
        widget.type === "photo"
      ) return widget.options.maxSize
      return null
    },

    mask (): Nullable<string> {
      const { widget } = this.field
      // TODO: убрать когда маски появятся в админке
      if (widget.type === "tel") return "+# ### ###-##-##"
      if (
        widget.type === "text" ||
        widget.type === "url" ||
        // @ts-ignore
        widget.type === "tel"
      ) return widget.options.mask
      return null
    },

    help (): string {
      return this.field.widget.options.help
    },
  },

  watch: {
    isVisible: {
      handler (isVisible) {
        if (!isVisible) this.model = undefined
      },
      // Нужно для сброса изначальной модели, но ломает ?status
      // immediate: true,
    },
  },

  created () {
    const { widgetType, model } = this
    const { dataType } = this.field

    if (
      widgetType === "checkbox" &&
      model === undefined &&
      !this.isRequired
    ) this.model = false
    else if (
      dataType.type === "status" &&
      model &&
      isObject(model)
    ) this.model = (model as Status).id || undefined
    else if (
      dataType.type === "tag" &&
      model &&
      isObject(model)
    ) this.model = (model as Tag).id || undefined
    else if (
      dataType.type === "array" &&
      model &&
      model[0] &&
      isObject(model[0])
    ) if (dataType.options.of?.type === "tag")
      this.model = model.map((t: Tag) => t.id)
  },

  mounted () {
    this.fetchOptions()
  },

  methods: {
    ...mapActions("app", {
      fetchStatuses: "FETCH_STATUSES",
      fetchTags: "FETCH_TAGS",
    }),

    async fetchOptions () {
      const { field } = this
      const { widget } = field
      if (widget.type !== "select") return
      const { type } = field.dataType
      let options: Record<string, any> = {}

      if (type === "status") {
        await this.fetchStatuses()
        options = this.statusOptions
      } else if (type === "tag") {
        await this.fetchTags()
        options = this.tagsOptions
      } else {
        options = Object.fromEntries(widget.options.choices
          .map(c => [c.label, c.value]))
      }

      const { other } = widget.options
      if (other) options[other] = "other"

      this.options = options
    },
  },
})
